<template>
  <div class="manage-floor-plans">
    <floor-plans
      class="mt-2"
      :floorPlans="floorPlans"
      :pagination="floorPlansPagination"
      :paginationParams.sync="floorPlanPaginationParams"
      :getFloorPlans="getFloorPlans"
      :isLoading.sync="isLoading"
      :canAdd="canAdd"
      :canEdit="canEdit"
      :canDelete="canDelete"
      @toDetail="toFloorPlanDetail"
      @addFloorPlan="openAddFPModal"
      @editFloorPlan="openEditFPModal"
      @deleteFloorPlan="deleteFloorPlan"
    ></floor-plans>

    <!-- ======================== Modal: Add New ========================= -->
    <modal v-model="addNewFPModal.isShown" fullscreen>
      <add-floor-plan
        :unitTypeId="unitTypeId"
        :propertyTypeId="propertyTypeId"
        @cancel="addNewFPModal.isShown = false"
        @submit="createFloorPlan"
      ></add-floor-plan>
    </modal>
    <!-- ========================== Modal: Edit ============================ -->
    <modal v-model="editFPModal.isShown" fullscreen>
      <edit-floor-plan
        :type="type"
        :floorPlanId="editFPModal.floorPlanId"
        :propertyTypeId="propertyTypeId"
        @cancel="editFPModal.isShown = false"
        @submit="updateFloorPlan"
      ></edit-floor-plan>
    </modal>
  </div>
</template>

<script>
import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectFloorPlanAPI from "@/modules/Project/api/projectFloorPlan";

export default {
  components: {
    FloorPlans: () =>
      import("@/modules/Project/components/FloorPlan/FloorPlans"),
    AddFloorPlan: () =>
      import("@/modules/Project/components/FloorPlan/AddFloorPlan"),
    EditFloorPlan: () =>
      import("@/modules/Project/components/FloorPlan/EditFloorPlan")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      type: [Number, String]
    },
    unitTypeId: {
      type: [Number, String]
    },
    propertyTypeId: {
      type: [Number, String]
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      floorPlanAPI: new ProjectFloorPlanAPI(this.type),

      isLoading: false,

      floorPlans: [],
      floorPlansPagination: {},

      // Filter Related
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },
      // Pagination
      floorPlanPaginationParams: {
        page: 1,
        perPage: 4
      },
      // Modals
      addNewFPModal: {
        isShown: false
      },
      editFPModal: {
        floorPlanId: "",
        isShown: false
      },

      addNewUnitModal: {
        isShown: false
      },
      floorPlanId: ""
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getFloorPlans()]);
    },

    openAddFPModal() {
      this.addNewFPModal.isShown = true;
    },
    openEditFPModal(id) {
      this.editFPModal.floorPlanId = id;
      this.editFPModal.isShown = true;
    },

    toFloorPlanDetail(floorPlanId) {
      this.$router.push({
        name: "ManageProjectFloorPlanDetails",
        params: {
          projectId: this.projectId,
          unitTypeId: this.unitTypeId,
          id: floorPlanId
        }
      });
    },

    openAddNewUnitModal(id) {
      this.floorPlanId = id;
      this.addNewUnitModal.isShown = true;
    },

    // ====================== API related methods ======================
    async getFloorPlans() {
      try {
        this.isLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.floorPlanAPI.getAllFloorPlans({
          queries: {
            "projectUnitType:id": this.unitTypeId,
            ...filteredParam
          },
          page: this.floorPlanPaginationParams.page,
          perPage: this.floorPlanPaginationParams.perPage
        });
        this.floorPlans = this._.cloneDeep(data.data);
        this.floorPlansPagination = this._.cloneDeep(data.meta.pagination);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch floor plans. Please try again later."
        });
      }
    },

    async createFloorPlan(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.floorPlanAPI.createFloorPlan(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Floor plan has been created successfully."
        });
        this.addNewFPModal.isShown = false;
        await this.getFloorPlans();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: error.response.data.detail
        });
      }
    },

    async updateFloorPlan(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.floorPlanAPI.updateFloorPlan({
          id: id,
          payload: payload
        });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Floor plan has been updated successfully."
        });
        this.editFPModal.isShown = false;
        await this.getFloorPlans();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: error.response.data.detail
        });
      }
    },

    async deleteFloorPlan(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.floorPlanAPI.deleteFloorPlan(id);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Floor plan has been deleted successfully."
        });
        await this.getFloorPlans();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-floor-plans {
  .spinner-container {
    min-height: 300px;
  }
}
</style>
